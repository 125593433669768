import {
    $, addAction, addFilter, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $footer = $('.footer-nav');
    addFilter('css-vars/register', (styles) => {
        const style = {};
        style['footer-height'] = () => `${$footer.outerHeight(true)}px`;
        return { ...styles, ...style };
    });
});
